import {
  filter,
  find,
  get,
  head,
  indexOf,
  isEmpty,
  isString,
  last,
  pick,
} from "lodash";

import {
  CLOSED,
  CLOSED_FOR_LUNCH,
  CLOSES_$,
  LOCKER,
  OPENS_$,
  SHOP,
} from "~/constants/strings";
import {
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_TYPE,
} from "~/constants/pickupPoint";
import { ShipmentEntity } from "~/constants/forms";
import { formatMessage, joinStringsWithComma } from "~/utils/string";
import {
  getCurrentDay,
  getTimeMomentDate,
  getTimeString,
  isCurrentTimeAfter,
  isCurrentTimeBefore,
  isCurrentTimeBetween,
} from "~/utils/date";
import { getValue } from "~/utils/object";

export const getPickupPointType = pickupPoint =>
  pickupPoint.pickupLocation.kind === PICKUP_POINT_TYPE.SHOP ? SHOP : LOCKER;

export const getPickupPointAddress = pickupPoint =>
  joinStringsWithComma([
    get(pickupPoint, "pickupLocation.address.organisation"),
    get(pickupPoint, "pickupLocation.address.street"),
    get(pickupPoint, "pickupLocation.address.postcode"),
  ]);

export const getPickupLocationCode = pickupPoint =>
  get(pickupPoint, "pickupLocation.pickupLocationCode");

export const calculateMapFitBounds = ({
  initialCoordinates,
  pickupPoints = [],
}) => {
  const bounds = pickupPoints.reduce(
    (
      bounds,
      {
        pickupLocation: {
          addressPoint: { latitude, longitude },
        },
      }
    ) => {
      bounds.southWest.longitude = Math.max(
        longitude,
        bounds.southWest.longitude
      );
      bounds.southWest.latitude = Math.min(latitude, bounds.southWest.latitude);

      bounds.northEast.longitude = Math.min(
        longitude,
        bounds.northEast.longitude
      );
      bounds.northEast.latitude = Math.max(latitude, bounds.northEast.latitude);

      return bounds;
    },
    {
      southWest: {
        longitude: initialCoordinates.longitude,
        latitude: initialCoordinates.latitude,
      },
      northEast: {
        longitude: initialCoordinates.longitude,
        latitude: initialCoordinates.latitude,
      },
    }
  );

  // Bounds format: [[south, west], [north, east]]
  return [
    [bounds.southWest.longitude, bounds.southWest.latitude],
    [bounds.northEast.longitude, bounds.northEast.latitude],
  ];
};

export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const weekdaysShort = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const getCurrentDayNumber = () => indexOf(weekdays, getCurrentDay()) + 1;

export const getPickupShortName = pickupPoint =>
  get(pickupPoint, "pickupLocation.shortName");

export const getPickupAddress = pickupPoint =>
  get(pickupPoint, "pickupLocation.address", {});

export const filterByType = (pickupPoints, type) =>
  type
    ? filter(pickupPoints, {
        pickupLocation: { kind: type },
      })
    : pickupPoints;

export const getPickupAddressPoint = pickupPoint =>
  get(pickupPoint, "pickupLocation.addressPoint");

const getOpenWindowStart = openWindow =>
  get(openWindow, "pickupLocationOpenWindowStartTime");

const getOpenWindowEnd = openWindow =>
  get(openWindow, "pickupLocationOpenWindowEndTime");

const getDayOpenWindows = (pickupPoint, dayNumber) => {
  const pickupLocationOpenWindow = get(
    pickupPoint,
    "pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow",
    []
  );

  const openWindows = filter(pickupLocationOpenWindow, [
    "pickupLocationOpenWindowDay",
    dayNumber,
  ]);

  if (isEmpty(openWindows)) {
    return {};
  }

  const openTime = getOpenWindowStart(head(openWindows));
  const closeTime = getOpenWindowEnd(last(openWindows));

  const breakWindows = [];
  for (let i = 0; i < openWindows.length - 1; i++) {
    const currentEndTime = getOpenWindowEnd(openWindows[i]);
    const nextStartTime = getOpenWindowStart(openWindows[i + 1]);
    if (
      getTimeMomentDate(currentEndTime).isBefore(
        getTimeMomentDate(nextStartTime)
      )
    ) {
      breakWindows.push({ start: currentEndTime, end: nextStartTime });
    }
  }

  return {
    openTime,
    closeTime,
    breaks: breakWindows,
  };
};

export const getOpenSaturday = pickupPoint =>
  get(pickupPoint, "pickupLocation.openSaturday");

export const getOpenSunday = pickupPoint =>
  get(pickupPoint, "pickupLocation.openSunday");

const isPickupOpenAt = (pickupPoint, day) => {
  switch (day) {
    case "Saturday":
      return getOpenSaturday(pickupPoint);
    case "Sunday":
      return getOpenSunday(pickupPoint);
    default:
      return true;
  }
};

const getNextOpenDayOpensStatus = (currentDayNumber, pickupPoint) => {
  const getDayNumber = day => get(day, "pickupLocationOpenWindowDay");
  const allDaysOpenWindows = get(
    pickupPoint,
    "pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow"
  );

  if (isEmpty(allDaysOpenWindows)) {
    return "";
  }

  const nextOpenDay =
    find(allDaysOpenWindows, day => getDayNumber(day) > currentDayNumber) ||
    head(allDaysOpenWindows);
  const nextOpenDayNumber = getDayNumber(nextOpenDay);
  const { openTime } = getDayOpenWindows(pickupPoint, nextOpenDayNumber);

  return formatMessage(
    OPENS_$,
    `${weekdaysShort[nextOpenDayNumber - 1]} ${getTimeString(openTime)}`
  );
};

export const getOpenStatusObject = pickupPoint => {
  const currentDayName = getCurrentDay();
  const currentDayNumber = getCurrentDayNumber();
  const currentDayOpenWindows = getDayOpenWindows(
    pickupPoint,
    currentDayNumber
  );

  const isOpenDay =
    isPickupOpenAt(pickupPoint, currentDayName) &&
    !isEmpty(currentDayOpenWindows);
  const { openTime, closeTime, lunchStart, lunchEnd } = currentDayOpenWindows;

  let openStatusObject = {};

  if (!isOpenDay || isCurrentTimeAfter(closeTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: getNextOpenDayOpensStatus(currentDayNumber, pickupPoint),
    };
  }

  if (isCurrentTimeBefore(openTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: formatMessage(OPENS_$, getTimeString(openTime)),
    };
  }

  if (isCurrentTimeBetween(openTime, closeTime)) {
    openStatusObject = {
      isOpen: true,
      openStatus: formatMessage(CLOSES_$, getTimeString(closeTime)),
    };
  }

  if (isCurrentTimeBetween(lunchStart, lunchEnd)) {
    openStatusObject = {
      isOpen: false,
      openStatus: CLOSED_FOR_LUNCH,
    };
  }

  if (lunchStart && lunchEnd) {
    openStatusObject = {
      ...openStatusObject,
      closedForLunch: `${lunchStart} - ${lunchEnd}`,
    };
  }

  return openStatusObject;
};

export const getPickupKind = pickupPoint =>
  get(pickupPoint, "pickupLocation.kind");

export const getDistanceToPickup = pickupPoint => {
  const distance = get(pickupPoint, "distance");

  return distance ? distance.toFixed(2) : "";
};

export const getPartnerLogo = pickupPoint =>
  get(pickupPoint, "pickupLocation.partnerImageLogo");

export const getPickupAmenities = (pickupPoint = {}) =>
  pick(pickupPoint.pickupLocation, [
    PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS,
    PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS,
    PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING,
  ]);

export const getDayNameByShortName = shortName =>
  weekdays[indexOf(weekdaysShort, shortName)];

const mergeOpenWindows = openWindows => {
  const getMergedOpenWindowObject = mergedOpenSlots => ({
    day:
      mergedOpenSlots.length > 1
        ? `${head(mergedOpenSlots).day} - ${last(mergedOpenSlots).day}`
        : head(mergedOpenSlots).day,
    openWindow: head(mergedOpenSlots).openWindow,
  });

  const mergedOpenWindowList = openWindows
    .reduce((groups, window) => {
      const groupDays = last(groups);

      if (!groupDays || last(groupDays).openWindow !== window.openWindow) {
        groups.push([window]);
      } else {
        groupDays.push(window);
      }
      return groups;
    }, [])
    .map(groupDays => getMergedOpenWindowObject(groupDays));

  return mergedOpenWindowList;
};

const mergeTimeWindows = (windows, breakStart, breakEnd) => {
  const mergedWindows = [];
  for (const window of windows) {
    if (window.end <= breakStart || window.start >= breakEnd) {
      mergedWindows.push(window);
    } else {
      if (window.start < breakStart) {
        mergedWindows.push({ start: window.start, end: breakStart });
      }
      if (window.end > breakEnd) {
        mergedWindows.push({ start: breakEnd, end: window.end });
      }
    }
  }
  return mergedWindows;
};

export const getPickupOpenWindowList = pickupPoint => {
  const openWindows = weekdaysShort.map((day, index) => {
    const dayOpenWindows = getDayOpenWindows(pickupPoint, index + 1);
    const isOpenDay =
      isPickupOpenAt(pickupPoint, getDayNameByShortName(day)) &&
      !isEmpty(dayOpenWindows);

    if (isOpenDay) {
      const { openTime, closeTime, breaks } = dayOpenWindows;

      let openWindows = [{ start: openTime, end: closeTime }];
      if (breaks && breaks.length > 0) {
        // Filter out breaks where start equals end
        const validBreaks = breaks.filter(b => b.start !== b.end);

        // Merge breaks into openWindows
        for (const b of validBreaks) {
          openWindows = mergeTimeWindows(openWindows, b.start, b.end);
        }
      }

      const openWindowStr = openWindows
        .map(w => `${w.start} - ${w.end}`)
        .join(", ");

      return {
        day,
        openWindow: openWindowStr,
      };
    } else {
      return {
        day,
        openWindow: CLOSED,
      };
    }
  });

  return mergeOpenWindows(openWindows);
};

export const splitTimeRange = timeRange =>
  timeRange.split("-").map(time => time.trim());

export const getFilteredPickupPoints = (pickupPoints, type, amenities = []) =>
  pickupPoints.filter(pickupPoint => {
    const matchesAmenities = amenities.length
      ? amenities.every(criteria =>
          get(pickupPoint, `pickupLocation.${criteria}`)
        )
      : true;
    const matchesType = type
      ? get(pickupPoint, "pickupLocation.kind") === type
      : true;
    return matchesType && matchesAmenities;
  });

export const getPickupDetails = pickupPoint => {
  const pickupLocationAddress = getValue(
    pickupPoint,
    "pickupLocation.address",
    {}
  );
  const formatAddressField = fieldValue =>
    isString(fieldValue) ? fieldValue.slice(0, 35) : fieldValue;

  return {
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_ORGANISATION]:
      formatAddressField(pickupLocationAddress.organisation),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_ADDRESS_LINE1]:
      formatAddressField(
        pickupLocationAddress.property || pickupLocationAddress.street
      ),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_ADDRESS_LINE2]:
      formatAddressField(pickupLocationAddress.locality),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_ADDRESS_LINE3]:
      formatAddressField(pickupLocationAddress.city),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_ADDRESS_LINE4]:
      formatAddressField(pickupLocationAddress.county),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_POST_CODE]:
      pickupLocationAddress.postcode,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_COUNTRY_CODE]:
      pickupLocationAddress.countryCode,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.ALLOW_REMOTE_PICKUP]:
      true,
  };
};
