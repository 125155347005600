import { get, isEmpty } from "lodash";

import { Spinner } from "@dpdgroupuk/mydpd-ui";

import { joinStringsWithComma } from "~/utils/string";
import { getPickupLocationCode } from "~/models/pickupPoint";
import { TRY_DIFFERENT_POSTCODE } from "~/constants/strings";
import NoPickupAvailable from "~/components/NoPickupAvailable";
import PickupDetails from "../PickupDetails/PickupDetails";

import styles from "./PickupPointsList.module.scss";

const PickupPointsList = ({
  pickupPoints,
  setActivePickupPoint,
  isLoading,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(pickupPoints)) {
    return (
      <NoPickupAvailable
        extraText={TRY_DIFFERENT_POSTCODE}
        className="d-flex h-100 flex-column align-items-center justify-content-center"
      />
    );
  }

  return pickupPoints.map(pickupPoint => (
    <div
      key={getPickupLocationCode(pickupPoint)}
      onClick={() => setActivePickupPoint(pickupPoint)}
      className={styles.itemContainer}
    >
      <h6>{get(pickupPoint, "pickupLocation.address.organisation")}</h6>
      <div>
        <span className={styles.address}>
          {joinStringsWithComma([
            get(pickupPoint, "pickupLocation.address.street"),
            get(pickupPoint, "pickupLocation.address.city") ||
              get(pickupPoint, "pickupLocation.address.town"),
            get(pickupPoint, "pickupLocation.address.postcode"),
          ])}
        </span>
      </div>
      <PickupDetails pickupPoint={pickupPoint} />
    </div>
  ));
};

export default PickupPointsList;
