import * as S from "~/constants/strings";

import styles from "./NoPickupAvailable.module.scss";

const NoPickupAvailable = ({ extraText, className }) => (
  <div className={className}>
    <div className={styles.title}>{S.SORRY}</div>
    <div className={styles.label}>{`${S.NO_PICKUP_POINTS_AVAILABLE}`}</div>
    {extraText && <div className={styles.label}>{extraText}</div>}
  </div>
);

export default NoPickupAvailable;
