import { useEffect, useState } from "react";
import classNames from "classnames";
import { find, includes, isEmpty, round, sortBy } from "lodash";
import { Button } from "@dpdgroupuk/mydpd-ui";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

import * as S from "~/constants/strings";
import SelectPickupPointModal from "~/components/SelectPickupPointModal";
import NoPickupAvailable from "~/components/NoPickupAvailable";
import {
  getPickupLocationCode,
  getPickupPointAddress,
  getPickupPointType,
} from "~/models/pickupPoint";
import { formatMessage } from "~/utils/string";

import styles from "./SelectPickupLocationList.module.scss";

const PickupLocation = ({
  pickupPoint,
  onPickupLocationChange,
  selectedPickupPoint,
  disabled,
}) => {
  const pickupLocationCode = getPickupLocationCode(pickupPoint);
  const { distance } = pickupPoint;

  return (
    <Form.Check
      key={pickupLocationCode}
      id={pickupLocationCode}
      type="radio"
      onChange={() => onPickupLocationChange(pickupPoint)}
      value={pickupLocationCode}
      checked={
        pickupLocationCode === getPickupLocationCode(selectedPickupPoint)
      }
      className={classNames(
        "mb-1",
        styles.radio,
        disabled && styles.radioDisabled
      )}
      label={
        <span>
          <span>{`${getPickupPointType(pickupPoint).toUpperCase()} - ${getPickupPointAddress(pickupPoint)} `}</span>
          {distance && (
            <span
              className={classNames(styles.title)}
            >{`(${formatMessage(S._$_MILES, round(distance, 1))})`}</span>
          )}
        </span>
      }
      disabled={disabled}
    />
  );
};

PickupLocation.propTypes = {
  pickupPoint: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
  selectedPickupPoint: PropTypes.object,
  disabled: PropTypes.bool,
};

const SelectPickupLocationList = ({
  deliveryAddress,
  pickupPoints = [],
  extraPickupLocation,
  setExtraPickupLocation,
  onPickupLocationChange,
  fetchPickupLocationsByPostcode,
  input,
  meta,
  disabled,
}) => {
  const pickupLocationCode = input.value;
  const [displaySelectPickupModal, setDisplaySelectPickupModal] =
    useState(false);

  const selectedPickupPoint = find([...pickupPoints, extraPickupLocation], {
    pickupLocation: {
      pickupLocationCode,
    },
  });

  const closestPickupPoints = sortBy(pickupPoints, "distance").slice(0, 3);
  const closestPickupLocationCodes = closestPickupPoints.map(pickupPoints =>
    getPickupLocationCode(pickupPoints)
  );

  const isClosestPickupPointsAvailable = !isEmpty(pickupPoints);

  const handlePickupLocationChange = pickupPoint => {
    const pickupLocationCode = getPickupLocationCode(pickupPoint);

    const isExtraPickupLocation = !find(
      [...pickupPoints, extraPickupLocation],
      {
        pickupLocation: {
          pickupLocationCode,
        },
      }
    );

    if (isExtraPickupLocation) {
      setExtraPickupLocation(pickupPoint);
    }

    input.onChange(getPickupLocationCode(pickupPoint));
    onPickupLocationChange(pickupPoint);
  };

  useEffect(() => {
    const isSelectedPickupPointClose =
      pickupLocationCode &&
      includes(closestPickupLocationCodes, pickupLocationCode);

    if (selectedPickupPoint && !isSelectedPickupPointClose) {
      setExtraPickupLocation(selectedPickupPoint);
    }
  }, [
    pickupLocationCode,
    selectedPickupPoint,
    closestPickupLocationCodes,
    pickupPoints,
    setExtraPickupLocation,
  ]);

  const isAnyPickupPointAvailable =
    isClosestPickupPointsAvailable || extraPickupLocation;

  const displayExtraPickupLocation =
    extraPickupLocation &&
    !find(closestPickupPoints, {
      pickupLocation: {
        pickupLocationCode: getPickupLocationCode(extraPickupLocation),
      },
    });

  return (
    <Row>
      <Col className="d-flex flex-column">
        {isAnyPickupPointAvailable && (
          <div
            className={classNames(
              "mb-1",
              styles.title,
              disabled && styles.titleDisabled
            )}
          >
            {S.SELECT_PICKUP_LOCATION}
          </div>
        )}

        <div
          className={classNames(
            "d-flex",
            !isClosestPickupPointsAvailable && "flex-column"
          )}
        >
          <div>
            {isClosestPickupPointsAvailable &&
              closestPickupPoints.map((pickupPoint, index) => (
                <PickupLocation
                  key={pickupPoint?.pickupLocation?.pickupLocationCode}
                  pickupPoint={pickupPoint}
                  selectedPickupPoint={selectedPickupPoint}
                  onPickupLocationChange={handlePickupLocationChange}
                  disabled={disabled}
                />
              ))}
            {displayExtraPickupLocation && (
              <PickupLocation
                pickupPoint={extraPickupLocation}
                selectedPickupPoint={selectedPickupPoint}
                onPickupLocationChange={handlePickupLocationChange}
                disabled={disabled}
              />
            )}
            {!isAnyPickupPointAvailable && <NoPickupAvailable />}
          </div>

          <Button
            variant="dark"
            onClick={() => setDisplaySelectPickupModal(true)}
            className={classNames(styles.seeMoreButton, "mb-1")}
            disabled={disabled}
          >
            {extraPickupLocation ? S.CHANGE : S.SEE_MORE}
          </Button>
        </div>
        <span className="mt-1 text-danger align-self-end">
          {meta.submitFailed && meta.error}
        </span>

        {/* Pickup locations modal */}
        {displaySelectPickupModal && (
          <SelectPickupPointModal
            deliveryAddress={deliveryAddress}
            pickupPoints={pickupPoints}
            onHide={() => setDisplaySelectPickupModal(false)}
            selectedPickupPoint={selectedPickupPoint}
            onPickupLocationChange={handlePickupLocationChange}
            fetchPickupLocationsByPostcode={fetchPickupLocationsByPostcode}
            open
          />
        )}
      </Col>
    </Row>
  );
};

SelectPickupLocationList.propTypes = {
  deliveryAddress: PropTypes.object,
  pickupPoints: PropTypes.array,
  input: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
  fetchPickupLocationsByPostcode: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectPickupLocationList;
