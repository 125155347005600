import { useEffect, useMemo, useState } from "react";
import Map, { Marker } from "react-map-gl";
import PropTypes from "prop-types";
import { get, isEmpty, pick } from "lodash";

import {
  calculateMapFitBounds,
  getPickupLocationCode,
} from "~/models/pickupPoint";
import { PICKUP_POINT_TYPE } from "~/constants/pickupPoint";
import PickupShopPin from "../Icon/PickupShopPin";
import PickupLockerPin from "../Icon/PickupLockerPin";
import LocationPin from "../Icon/LocationPin";

import "mapbox-gl/dist/mapbox-gl.css";

const defaultCoordinates = {
  latitude: 54.9,
  longitude: -4,
};

const defaultMapZoom = 3.7;

const PickupPointsMap = ({
  deliveryAddress,
  pickupPoints = [],
  onPickupPointClick,
  activePickupPoint,
  selectedPickupPoint,
}) => {
  const isDeliveryAddressCoordinatesAvailable =
    deliveryAddress?.latitude && deliveryAddress?.longitude;

  const initialCoordinates = pick(
    isDeliveryAddressCoordinatesAvailable
      ? deliveryAddress
      : get(pickupPoints, "[0].addressPoint"),
    ["latitude", "longitude"]
  );

  const isInitialCoordinatesAvailable =
    initialCoordinates?.latitude && initialCoordinates?.longitude;

  const [mapRef, setMapRef] = useState(null);

  const updateFitBounds = deliveryAddress
    ? !isEmpty(pickupPoints)
    : pickupPoints.length > 1;

  const fitBounds = useMemo(
    () =>
      updateFitBounds
        ? calculateMapFitBounds({
            initialCoordinates,
            pickupPoints,
          })
        : null,
    [initialCoordinates, updateFitBounds, pickupPoints]
  );

  useEffect(() => {
    if (mapRef && updateFitBounds) {
      mapRef.fitBounds(fitBounds, {
        padding: {
          top: 85,
          bottom: 25,
          left: 55,
          right: 55,
        },
        duration: 3000,
      });
    }

    if (mapRef && !isInitialCoordinatesAvailable) {
      mapRef.flyTo({
        center: [defaultCoordinates.longitude, defaultCoordinates.latitude],
        zoom: defaultMapZoom,
        essential: true,
      });
    }
  }, [mapRef, fitBounds, updateFitBounds, isInitialCoordinatesAvailable]);

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
      ref={ref => setMapRef(ref)}
      initialViewState={
        isInitialCoordinatesAvailable
          ? {
              ...initialCoordinates,
              zoom: 10,
            }
          : { ...defaultCoordinates, zoom: defaultMapZoom }
      }
      mapStyle="mapbox://styles/mapbox/streets-v11"
      logoPosition="top-right"
      fitBounds={fitBounds}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        background: "rgb(26, 29, 33)",
        borderRadius: 0,
        border: "none",
        zIndex: "1",
      }}
    >
      {pickupPoints.map(pickupPoint => {
        const pickupLocationCode = getPickupLocationCode(pickupPoint);
        const isActive =
          getPickupLocationCode(activePickupPoint) === pickupLocationCode;
        const isSelected =
          getPickupLocationCode(selectedPickupPoint) === pickupLocationCode;

        return (
          <Marker
            latitude={pickupPoint?.pickupLocation?.addressPoint?.latitude}
            longitude={pickupPoint?.pickupLocation?.addressPoint?.longitude}
            key={pickupLocationCode}
            anchor="bottom"
            onClick={() => onPickupPointClick(pickupPoint)}
            style={{ zIndex: (isSelected && 2) || (isActive && 3) || 1 }}
          >
            {pickupPoint &&
            pickupPoint.pickupLocation.kind === PICKUP_POINT_TYPE.SHOP ? (
              <PickupShopPin cursor="pointer" />
            ) : (
              <PickupLockerPin cursor="pointer" />
            )}
          </Marker>
        );
      })}
      {isDeliveryAddressCoordinatesAvailable && (
        <Marker
          latitude={deliveryAddress?.latitude}
          longitude={deliveryAddress?.longitude}
          anchor="bottom"
        >
          <LocationPin />
        </Marker>
      )}
    </Map>
  );
};

PickupPointsMap.propTypes = {
  deliveryAddress: PropTypes.object,
  pickupPoints: PropTypes.array,
  onPickupPointClick: PropTypes.func,
  activePickupPoint: PropTypes.object,
  selectedPickupPoint: PropTypes.object,
};

export default PickupPointsMap;
