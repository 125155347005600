import { useMemo, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { toUpper } from "lodash";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import { LIST_VIEW, MAP_VIEW, SELECT_NEW_PICKUP } from "~/constants/strings";
import { getFilteredPickupPoints } from "~/models/pickupPoint";
import { Cross } from "~/components/Icon";
import PickupPointsMap from "../PickupPointsMap";
import Filters from "./Filters/Filters";
import ActivePickupPoint from "./ActivePickupPoint/ActivePickupPoint";
import PickupPointsList from "./PickupPointsList/PickupPointsList";
import styles from "./SelectPickupPointModal.module.scss";

const SelectPickupPointModal = ({
  deliveryAddress,
  open,
  onHide,
  pickupPoints,
  selectedPickupPoint,
  onPickupLocationChange,
  fetchPickupLocationsByPostcode,
}) => {
  const [postcodePickupLocations, setPostcodePickupLocations] = useState(null);
  const [activePickupPoint, setActivePickupPoint] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [loadingPickupLocations, setLoadingPickupLocations] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const filteredPickupPoints = useMemo(
    () =>
      getFilteredPickupPoints(
        postcodePickupLocations || pickupPoints,
        selectedType,
        selectedAmenities
      ),
    [pickupPoints, postcodePickupLocations, selectedAmenities, selectedType]
  );

  const onSearchPickupLocations = postcode =>
    fetchPickupLocationsByPostcode({
      postcode,
      setPickupLocations: setPostcodePickupLocations,
      setIsLoading: setLoadingPickupLocations,
    });

  return (
    <Modal show={open} centered size="sm" dialogClassName={styles.container}>
      <>
        <Modal.Header className="w-100 d-flex justify-content-between">
          <div className="w-100 d-flex justify-content-between">
            <span>{!activePickupPoint && SELECT_NEW_PICKUP}</span>
            <Cross
              cursor="pointer"
              fill="black"
              fill-opacity="0.56"
              onClick={
                activePickupPoint ? () => setActivePickupPoint(null) : onHide
              }
              className="align-self-end"
            />
          </div>
        </Modal.Header>

        {activePickupPoint && (
          <ActivePickupPoint
            pickupPoint={activePickupPoint}
            onSelectPickupPoint={() => {
              onHide();
              setActivePickupPoint(null);
              onPickupLocationChange(activePickupPoint);
            }}
          />
        )}
        <Modal.Body
          className={classNames(styles.body, activePickupPoint && "d-none")}
        >
          <div className={classNames(styles.mapContainer)}>
            <PickupPointsMap
              deliveryAddress={deliveryAddress}
              pickupPoints={filteredPickupPoints}
              onPickupPointClick={setActivePickupPoint}
              selectedPickupPoint={selectedPickupPoint}
              activePickupPoint={activePickupPoint}
            />
          </div>
          <div
            className={classNames(
              styles.listContainer,
              isListView ? styles.expandedList : styles.collapsedList
            )}
          >
            <Button
              variant="light"
              onClick={() => setIsListView(!isListView)}
              className="ml-3 mb-3 rounded-pill font-weight-bold bg-white border-0"
            >
              {toUpper(isListView ? MAP_VIEW : LIST_VIEW)}
            </Button>
            <Filters
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedAmenities={selectedAmenities}
              setSelectedAmenities={setSelectedAmenities}
              deliveryAddress={deliveryAddress}
              onSearchPickupLocations={onSearchPickupLocations}
            />
            <div
              className={classNames(
                styles.pickupPointsListContainer,
                "bg-white rounded-bottom"
              )}
            >
              <PickupPointsList
                pickupPoints={filteredPickupPoints}
                setActivePickupPoint={setActivePickupPoint}
                isLoading={loadingPickupLocations}
              />
            </div>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

SelectPickupPointModal.propTypes = {
  deliveryAddress: PropTypes.object,
  open: PropTypes.bool,
  onHide: PropTypes.func,
  pickupPoints: PropTypes.array,
  selectedPickupPoint: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
  fetchPickupLocationsByPostcode: PropTypes.func,
};

export default SelectPickupPointModal;
