import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { referenceApi } from "~/apis";

export const ReferenceActionTypes = createActionTypes("REFERENCE", {
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
  FETCH_EXPORT_REASONS: createAsyncActionTypes("FETCH_EXPORT_REASONS"),
  FETCH_OUTBOUND_SERVICES: createAsyncActionTypes("FETCH_OUTBOUND_SERVICES"),
  FETCH_INBOUND_SERVICES: createAsyncActionTypes("FETCH_INBOUND_SERVICES"),
  FETCH_CURRENCIES: createAsyncActionTypes("FETCH_CURRENCIES"),
  FETCH_COMMODITIES_BY_FILTER: createAsyncActionTypes(
    "FETCH_COMMODITIES_BY_FILTER"
  ),
  FETCH_COMMODITIES: createAsyncActionTypes("FETCH_COMMODITIES"),
  FETCH_COMMODITIES_BY_CODE: createAsyncActionTypes(
    "FETCH_COMMODITIES_BY_CODE"
  ),
  FETCH_NETWORKS: createAsyncActionTypes("FETCH_NETWORKS"),
  FETCH_GROUP_DISPATCH_SERVICES: createAsyncActionTypes(
    "FETCH_GROUP_DISPATCH_SERVICES"
  ),
  FETCH_NI_SHIPMENT_REQUIRED_FIELDS: createAsyncActionTypes(
    "FETCH_NI_SHIPMENT_REQUIRED_FIELDS"
  ),
  FETCH_PICKUP_LOCATIONS: createAsyncActionTypes("FETCH_PICKUP_LOCATIONS"),
  ADD_PICKUP_LOCATION: "ADD_PICKUP_LOCATION",
  SET_ACTIVE_OUTBOUND_SERVICE: "SET_ACTIVE_OUTBOUND_SERVICE",
  SET_ACTIVE_INBOUND_SERVICE: "SET_ACTIVE_INBOUND_SERVICE",
  CLEAR_SERVICES: "CLEAR_SERVICES",
  CLEAR_INBOUND_SERVICES: "CLEAR_INBOUND_SERVICES",
  CLEAR_EXPORT_REASONS: "CLEAR_EXPORT_REASONS",
  CLEAR: "CLEAR",
});

export const fetchCountries = createAsyncAction(
  referenceApi.fetchCountries,
  ReferenceActionTypes.FETCH_COUNTRIES
);

export const fetchOutboundServices = createAsyncAction(
  referenceApi.fetchOutboundServices,
  ReferenceActionTypes.FETCH_OUTBOUND_SERVICES
);

export const fetchExportReasons = createAsyncAction(
  referenceApi.fetchExportReasons,
  ReferenceActionTypes.FETCH_EXPORT_REASONS
);

export const fetchInboundServices = createAsyncAction(
  referenceApi.fetchInboundServices,
  ReferenceActionTypes.FETCH_INBOUND_SERVICES
);

export const fetchCurrencies = createAsyncAction(
  referenceApi.fetchCurrencies,
  ReferenceActionTypes.FETCH_CURRENCIES
);

export const fetchNetworks = createAsyncAction(
  query => referenceApi.fetchNetworks(query).then(({ data }) => data?.[0]),
  ReferenceActionTypes.FETCH_NETWORKS
);

export const fetchCommoditiesByFilter = createAsyncAction(
  referenceApi.fetchCommoditiesByFilter,
  ReferenceActionTypes.FETCH_COMMODITIES_BY_FILTER
);

export const fetchCommodities = createAsyncAction(
  (query, options) => referenceApi.fetchCommodities(query, options),
  ReferenceActionTypes.FETCH_COMMODITIES
);

export const fetchCommoditiesByCode = createAsyncAction(
  (query, options) => referenceApi.fetchCommoditiesByCode(query, options),
  ReferenceActionTypes.FETCH_COMMODITIES_BY_CODE
);

export const clearServices = () => ({
  type: ReferenceActionTypes.CLEAR_SERVICES,
});

export const clearInboundServices = () => ({
  type: ReferenceActionTypes.CLEAR_INBOUND_SERVICES,
});

export const clearExportReasons = () => ({
  type: ReferenceActionTypes.CLEAR_EXPORT_REASONS,
});

export const clearReference = () => ({
  type: ReferenceActionTypes.CLEAR,
});

export const setActiveOutboundService = payload => ({
  type: ReferenceActionTypes.SET_ACTIVE_OUTBOUND_SERVICE,
  payload,
});

export const setActiveInboundService = payload => ({
  type: ReferenceActionTypes.SET_ACTIVE_INBOUND_SERVICE,
  payload,
});

export const fetchGroupDispatchServices = createAsyncAction(
  referenceApi.fetchGroupDispatchServices,
  ReferenceActionTypes.FETCH_GROUP_DISPATCH_SERVICES
);

export const fetchNiShipmentRequiredFields = createAsyncAction(
  referenceApi.fetchRequiredFieldsForDomesticZone,
  ReferenceActionTypes.FETCH_NI_SHIPMENT_REQUIRED_FIELDS
);

export const fetchPickupLocations = createAsyncAction(
  ({ postcode }) =>
    referenceApi.fetchPickupLocations({ searchAddress: postcode }),
  ReferenceActionTypes.FETCH_PICKUP_LOCATIONS
);

export const addPickupLocation = payload => ({
  type: ReferenceActionTypes.ADD_PICKUP_LOCATION,
  payload,
});
