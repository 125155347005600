import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { find, get, isEmpty } from "lodash";

import {
  fetchPickupLocationByCode,
  fetchPickupLocations,
} from "~/apis/reference";
import { ShipmentEntity } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import { SHIP_TO_SHOP } from "~/constants/services";
import * as S from "~/constants/strings";
import { PickupLocationModels, PostcodeModels } from "~/models";
import { ReferenceActions, ReferenceSelectors } from "~/redux";
import { formatMessage } from "~/utils/string";

export default compose(
  withState("deliveryAddress", "setDeliveryAddress", null),
  withState("extraPickupLocation", "setExtraPickupLocation", null),
  withState("pickupLocationsPostcode", "setPickupLocationsPostcode", null),
  connect((state, { pageConfig }) => ({
    pickupLocations: ReferenceSelectors.getPickupLocations(state),
  })),
  withHandlers({
    onPickupLocationChange:
      ({ reInitializeForm }) =>
      pickupPoint =>
        reInitializeForm(PickupLocationModels.getPickupDetails(pickupPoint)),
    fetchPickupLocations: ({
      dispatch,
      notifier,
      snackbar,
      overlay,
      selectedService,
      extraPickupLocation,
      setExtraPickupLocation,
      reInitializeForm,
      pickupLocationsPostcode,
      setPickupLocationsPostcode,
      pickupLocations,
    }) =>
      notifier.runAsync(
        async ({
          postcode,
          pickupLocationCode,
          clearSelectedPickupLocation = true,
        }) => {
          const isShipToShopServiceSelected = SHIP_TO_SHOP.includes(
            selectedService?.networkKey
          );
          const isNewPostcode = pickupLocationsPostcode !== postcode;
          const isPostcodeValid = PostcodeModels.isGBPostCodeFormat(postcode);
          const shouldFetchPickupLocations =
            isShipToShopServiceSelected &&
            isPostcodeValid &&
            (isNewPostcode || isEmpty(pickupLocations));

          if (shouldFetchPickupLocations) {
            try {
              overlay.show();

              const { data } = await dispatch(
                ReferenceActions.fetchPickupLocations({
                  postcode,
                })
              );
              setPickupLocationsPostcode(postcode);

              // clear selected pickup details on fetch new pickup locations
              if (clearSelectedPickupLocation) {
                reInitializeForm({ "outboundConsignment.pickupDetails": null });
              }

              setExtraPickupLocation(null);

              const fetchExtraPickupLocation =
                pickupLocationCode &&
                !extraPickupLocation &&
                !find(data, {
                  pickupLocation: {
                    pickupLocationCode,
                  },
                });

              // fetch different postcode extra pickup location
              if (fetchExtraPickupLocation) {
                const { data } =
                  await fetchPickupLocationByCode(pickupLocationCode);

                setExtraPickupLocation({
                  addressPoint: data.addressPoint,
                  pickupLocation: data,
                });
              }
            } catch (e) {
              snackbar.showAlert({
                message: formatMessage(
                  S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
                  S.PICKUP_LOCATIONS
                ),
                displayTime: SHOW_ALERT_DISPLAY_TIME,
              });
            } finally {
              overlay.hide();
            }
          }
        }
      ),
    fetchPickupLocationsByPostcode: ({ notifier, snackbar }) =>
      notifier.runAsync(
        async ({ postcode, setPickupLocations, setIsLoading }) => {
          try {
            setIsLoading(true);
            const { data } = await fetchPickupLocations({
              searchAddress: postcode,
            });
            setPickupLocations(data);
          } catch (e) {
            snackbar.showAlert({
              message: formatMessage(
                S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
                S.PICKUP_LOCATIONS
              ),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          } finally {
            setIsLoading(false);
          }
        }
      ),
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        selectedService,
        createShipmentValues,
        fetchPickupLocations,
        shipment,
      } = this.props;

      // fetch pickup locations on ship to shop service select
      if (
        prevProps.selectedService?.networkKey !== selectedService?.networkKey
      ) {
        const createShipmentValuesPostcode = get(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        );
        const pickupLocationCode = get(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
            .PICKUP_LOCATION_CODE
        );
        const shipmentPostcode = get(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        );
        const postcode = createShipmentValuesPostcode || shipmentPostcode;

        if (postcode) {
          fetchPickupLocations({
            postcode,
            pickupLocationCode,
            clearSelectedPickupLocation: false,
          });
        }
      }
    },
  })
);
